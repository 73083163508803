import React from 'react'
import {MobileDrawer} from 'wix-ui-tpa/MobileDrawer'
import {ActionsMenuLayout} from 'wix-ui-tpa/ActionsMenuLayout'
import {ReactComponent as Close} from 'wix-ui-tpa/dist/src/assets/icons/Close.svg'
import {useWidgetProps} from '../../../hooks/widget-props'
import {User} from '../../../icons'
import {isUserLoggedIn} from '../../../selectors/member'

export const LoginDrawer = () => {
  const {toggleLoginDrawer, promptLogin, member, logout, t} = useWidgetProps()
  const loggedIn = isUserLoggedIn(member)

  const handleLogin = () => {
    if (loggedIn) {
      logout()
    } else {
      promptLogin()
    }

    handleClose()
  }

  const handleClose = () => toggleLoginDrawer()

  return (
    <MobileDrawer isOpen onRequestClose={handleClose}>
      <div style={{width: '100%'}}>
        <ActionsMenuLayout>
          <ActionsMenuLayout.Item
            onClick={handleLogin}
            content={loggedIn ? t('logout') : t('login')}
            prefixIcon={<User />}
          />
          <ActionsMenuLayout.Item onClick={handleClose} content={t('close')} prefixIcon={<Close />} />
        </ActionsMenuLayout>
      </div>
    </MobileDrawer>
  )
}
